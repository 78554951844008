import { HexEditorClassNames } from '../types';

const CLASS_NAMES: HexEditorClassNames = {
  ascii: 'asciiValue',
  asciiHeader: 'asciiHeader',
  asciiValues: 'asciiValues',
  body: 'hexEditorBody',
  byte: 'byteValue',
  byteHeader: 'byteHeader',
  byteValues: 'byteValues',
  currentColumn: 'currentColumn',
  currentRow: 'currentRow',
  cursor: 'cursor',
  cursorHigh: 'cursorHigh',
  cursorLow: 'cursorLow',
  editAscii: 'editAscii',
  editHex: 'editHex',
  even: 'even',
  gutter: 'gutter',
  gutterHeader: 'gutterHeader',
  header: 'hexEditorHeader',
  highlight: 'highlight',
  invalid: 'invalid',
  notFocused: 'notFocused',
  nybbleHigh: 'nybbleHighValue',
  nybbleLow: 'nybbleLowValue',
  odd: 'odd',
  offsetLabel: 'offsetLabel',
  offsetLabelHeader: 'offsetLabelHeader',
  row: 'hexEditorRow',
  rowHeader: 'hexEditorRowHeader',
  selection: 'selection',
  selectionCursor: 'selectionCursor',
  selectionEnd: 'selectionEnd',
  selectionStart: 'selectionStart',
};

export default CLASS_NAMES;
